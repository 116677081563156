<style>
	.progress2d-box {
	--width: 100%;
	--height: 30px;
	--backheight: var(--height);
	--backwidth: var(--width); 
	
	--bar-percent: 0.8;
	--bar-not-zero: 1;
	--bar-width: calc( var(--bar-percent) * var(--backwidth) );
	--bar-hsl-h: 200;
	--bar-hsl-s: 80%;
	--bar-hsl-alpha: 1;
	--bar-color: hsl(var(--bar-hsl-h), var(--bar-hsl-s), 50%, var(--bar-hsl-alpha));
	--bar-color-light: hsl(var(--bar-hsl-h), var(--bar-hsl-s), 83%, var(--bar-hsl-alpha));
	--bar-color-heightlight: hsl(var(--bar-hsl-h), var(--bar-hsl-s), 87%, var(--bar-hsl-alpha));
	--bar-color-dark: hsl(var(--bar-hsl-h), var(--bar-hsl-s), 10%, var(--bar-hsl-alpha));
	
	--font-size: calc( var(--backheight) / 4 * 3 );
	
	--animation-time: 1s;
	
	position: relative;
	width: var(--backwidth);
	height: var(--height);
	}
	
	.progress2d-bg-back {
	width: var(--backwidth); 
	height: var(--backheight);
	background-color: #000000;
	position: absolute;
	left: 0; 
	top: 0;
	right: 0;
	bottom: 0;
	}
	
	@keyframes progress2d-bar-animation-Width {
	0%   { width: 0 }
	70%  { width: var(--bar-width) }
	100% { width: var(--bar-width) }
	}
	
	.progress2d-bar-front {
	width: var(--bar-width);
	animation-delay: 5s;
	-webkit-animation-delay: 5s; /* Safari 和 Chrome */
	animation: progress2d-bar-animation-Width var(--animation-time) ease-out infinite;
	background: linear-gradient(to right, var(--bar-color-dark), var(--bar-color));
	position: absolute;
	left: 0; 
	top: 0;
	bottom: 0;
	}
	
	.progress2d-text {
	font-size: calc( var(--font-size) );
	line-height: calc( var(--backheight) );
	position: absolute;
	right: 5px; 
	top: 0;
	padding: 0;
	color: #fff;
	text-shadow: 3px 3px 3px #000;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: var(--backwidth);
	}
	.progress2d-text.left {
	right: auto;
	left: 5px; 
	}
</style>

<template>
	<div :id="id" class="progress2d-box" :style="'--animation-time: ' + (during || 1) + 's; --height: ' + (height || '20px') + '; --bar-hsl-h: ' + (barcolorh || 210) + '; --bar-hsl-s: ' + (barcolors || '80%') + '; --bar-percent: ' + (scaledValue ? (scaledValue < 0 ? 0 : (scaledValue > 100 ? 1 : scaledValue / 100)) : 0) + '; ' + (fontsize ? '--font-size: ' + fontsize + '; ' : '')">
	<div class="progress2d-bg-back"></div>
	<div class="progress2d-bar-front"></div>
	<div v-if="showtext || text" :class="'progress2d-text ' + (align == 'left' ? align : '')" :style="'color: ' + (color || '#fff')" v-text="text ? text : (value ? (value < 0 ? 0 : (value > 100 ? 100 : value)) : 0) + '%'"></div>
	</div>
</template>

<script>
	export default {
		data: function() {
			return {
				id: 'progress2d_' + Math.random(),
				scaledValue: this.value * (this.valuescale || 1)
			}
		},
		props: [
			'barcolorh',
			'barcolors',
			'height',
			'value',
			'valuescale',
			'text',
			'align',
			'showtext',
			'color',
			'fontsize',
			'during',
		],
		methods: {
			
		}
	}
</script>
