// 系统弹出窗口管理

const ModelWindows = {
	// 系统所有弹出窗口集
	windows: [],
	
	// 记录系统所有弹出窗口
	// whose 		- 哪个模块
	// windowHandle - 哪个窗口的状态对象
	// openSate 	- 窗口状态值，一般为true时，表示窗口已打开
	// closeFun		- 关闭窗口的执行函数
	regist(id, whose, windowHandle, openState, closeFun, closeFunParams){
		this.windows.push({
			id, whose, windowHandle, openState, closeFun, closeFunParams
		})
	},

	// 关闭系统当前弹出窗口，以便打开新的或其它窗口
	close(ignoreID){
		this.windows.forEach(function(item){
			if(ignoreID != item.id){
				if(item.whose[item.windowHandle] == item.openState){
					if(item.closeFun) item.closeFun(item.closeFunParams);
				}
			}
		});
	}
};

export default ModelWindows
