<style>
	#dv-map{ opacity: 0; }

	/* 地图部分 */
	#dv-map-box{ position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: url('../../../public/imgs/img/bg/images/img_0.png') -8px 102px no-repeat; background-size: 94%; }
	#dv-map #dv-map-box .china{ position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: url('../../../public/imgs/全国区域地图.png') 0 0 no-repeat; background-size: 100%; }
	#dv-map #dv-map-box .china_soundsea{ position: absolute; right: 730px; top: 730px; width: 60px; }
	#dv-map #dv-map-box .programSign{ position: absolute; }
	#dv-map #dv-map-box .programSign .sign{ position: absolute; left: 0; top: 0; z-index: 0; cursor: pointer; }
	#dv-map #dv-map-box .programSign .sign2{ position: absolute; left: -40px; top: -40px; z-index: 0; cursor: pointer; }
	#dv-map #dv-map-box .programSign .flag{ position: absolute; left: 11px; top: 11px; width: 400px; pointer-events: none; }
	#dv-map #dv-map-box .programSign .sign_animate{ position: absolute; left: -40px; top: -40px; z-index: 2; pointer-events: none; }
	#dv-map #dv-map-box .programSign.hide{ display: none; }

	/* VR窗口部分 */
	#dv-map #webVRbox{ position: absolute; left: 0; top: 0; width: 100% }
    #dv-map #webVRbox .time { position: absolute; right: 45px; top: 50px; width: 400px; text-align: right; font-size: 18px; font-weight: 600; font-style: normal; color: rgb(0, 112, 216); }
	#dv-map #webVRbox .vrbrowser{ position: absolute; left: 500px; top: 100px; width: 1310px; height: 550px; background-color: #000000; border: 2px solid #0049FF; }
	#dv-map #webVRbox .vrbrowser iframe { border: 0; }

	#dv-map #webVRbox .btns    { position: absolute; left: 1825px; top: 100px; }
	
	#dv-map #webVRbox .bg_btn03{ position: absolute; left: 0; top: 0; cursor: pointer; }
	#dv-map #webVRbox .bg_btn04{ position: absolute; left: 0; top: 60px; cursor: pointer; }

	#dv-map #webVRbox .bg_btn25{ position: absolute; left: 0; top: 120px; cursor: pointer; }
	#dv-map #webVRbox .bg_btn26{ position: absolute; left: 0; top: 180px; cursor: pointer; }

	#dv-map #webVRbox .bg_btn11{ position: absolute; left: 0; top: 324px; cursor: pointer; }
	#dv-map #webVRbox .bg_btn12{ position: absolute; left: 0; top: 384px; cursor: pointer; }
	#dv-map #webVRbox .bg_btn14{ position: absolute; left: 0; top: 444px; cursor: pointer; }
	#dv-map #webVRbox .bg_btn13{ position: absolute; left: 0; top: 504px; cursor: pointer; }


	/* 作废的3个按钮：方向、旋转、VR模式 */
	#dv-map #webVRbox .bg_btn01{ position: absolute; left: 500px; top: 450px; }
	#dv-map #webVRbox .bg_btn02{ position: absolute; left: 500px; top: 450px; }
	#dv-map #webVRbox .bg_btn05{ position: absolute; left: 500px; top: 330px; cursor: pointer; }
	
	#dv-map .CurrentLightBox { position: absolute; left: 1190px; top: 60px; width: 1360px; opacity: 0; pointer-events: none; z-index: 99999; }

	#dv-map .ReportViewer { position: absolute; left: 1550px; top: 105px; width: 735px; height: 935px; overflow: hidden; display:none; z-index: 10000000; }
	#dv-map .ReportViewer .report_bg { position: absolute; left: 0; top: 0; width: 735px; height: 935px; }
	#dv-map .ReportViewer .report_pdf { position: absolute; left: 3px; top: 60px; width: 725px; height: 800px; }
	#dv-map .ReportViewer .report_btn { position: absolute; left: 700px; top: 28px; width: 26px; cursor: pointer; }
	#dv-map .ReportViewer .report_title { position: absolute; left: 10px; top: 30px; width: 635px; font-size: 16px; font-weight: bold; }

</style>

<template>
	<div id="dv-map">
		<div id="dv-map-box">
			<!--dv-img class="china" src="imgs/img/map/map.json" type="svg"></dv-img-->
			<!-- <dv-img class="china" :src="'imgs/' + currentArea + '地图.png'" @click="tellme"></dv-img> -->
			<div v-show="currentPageLevel != 3" class="china" :style="'left: ' + mapOffsetX + 'px; background-size: ' + (1440 * xyScale) + 'px'"></div>
			<div v-show="currentPageLevel != 3" class="programSign" v-for="(item, index) in ProjectList" :key="index" :style="'left: ' + (mapOffsetX + item.ScreenMapX * xyScale) + 'px; top: ' + (item.ScreenMapY * xyScale) + 'px'">
				<!-- <dv-img class="sign" src="imgs/图钉.png" :title="name" @click="selectProgram"></dv-img> -->
				<dv-img class="sign2" src="imgs/p20/1594027483255-8d35851a-d2d4-4d9d-bb34-319fb136ecb3.png" :scale="'left: -' + (40 * xyScale)  + 'px; top: -' + (40 * xyScale)  + 'px; width: ' + (130 * xyScale)  + 'px'" :title="item.Name" :data-id="item.Id" @click="selectProgram"></dv-img>
				<dv-img v-if="currentPageLevel == 2 && item.Id == currentProgram" class="sign_animate" :scale="'left: -' + (40 * xyScale)  + 'px; top: -' + (40 * xyScale)  + 'px; width: ' + (130 * xyScale)  + 'px'" src="imgs/p20/1594027483255-8d35851a-d2d4-4d9d-bb34-319fb136ecb3.png" :data-id="item.Id" @click="selectProgram"></dv-img>
				<dv-img v-if="currentPageLevel == 2 && item.Id == currentProgram" class="flag" :scale="'left: ' + (11 * xyScale)  + 'px; top: ' + (11 * xyScale)  + 'px; width: ' + (400 * xyScale)  + 'px'" :src="ImgBaseUrl + item.ShowScreenPic" :data-id="item.Id" @click="selectProgram"></dv-img>
			</div>
			<dv-img v-show="currentPageLevel != 3" class="china_soundsea" src="imgs/比例尺.png"></dv-img>
		</div>
		<div v-if="currentPageLevel == 3" id="webVRbox">
			<i class="time" v-text="currentTime"></i>

			<div class="vrbrowser">
				<iframe border="0" width="100%" height="100%" :src="vrURL"></iframe>
			</div>
			
			<div class="btns">
				<dv-img class="bg_btn11" :src="'imgs/p4/95d3ab63-9402-43c5-91be-18a9606a96bb.png'" @click="toggleDefect('衬砌厚度不足')"></dv-img>
				<dv-img class="bg_btn12" :src="'imgs/p4/37cbb04f-61d9-4de9-a3ec-635c055d8153.png'" @click="toggleDefect('钢筋缺失问题')"></dv-img>
				<dv-img class="bg_btn13" :src="'imgs/p4/89330cac-5598-4f6a-a953-492dac43bb58.png'" @click="toggleDefect('其他问题')"></dv-img>
				<dv-img class="bg_btn14" :src="'imgs/p4/bc4487d1-ddc3-420c-847b-3b0dd40ba078.png'" @click="toggleDefect('防裂钢筋网问题')"></dv-img>

				<dv-img class="bg_btn03" :src="'imgs/p4/2f49ae58-5562-4499-a548-dba9f2b4b752.png'" @click="goBack"></dv-img>
				<dv-img class="bg_btn04" :src="'imgs/p4/83469246-de44-4994-ac6e-5c7a470fea59.png'" @click="vrResetCamare"></dv-img>
				<dv-img class="bg_btn05" :src="'imgs/p4/1f3c0fda-4bea-4fb8-8b9e-0b185012736b.png'" @click="toggleVR"></dv-img>

				<dv-img v-if="hasReport" class="bg_btn25" :src="'imgs/p4/1ceca2bb-6776-4289-b750-d08ff9525a42.png'" @click="viewReport('report')"></dv-img>
				<dv-img v-if="hasRepairReport" class="bg_btn26" :src="'imgs/p4/1f3c0fda-4bea-4fb8-8b9e-0b185012736b.png'" @click="viewReport('repair')"></dv-img>
			
				<dv-img class="bg_btn01" :src="'imgs/p4/' + goImg + '.png'"></dv-img>
				<dv-img class="bg_btn02" :src="'imgs/p4/' + turnImg + '.png'"></dv-img>
			</div>

			<div class="ReportViewer">
				<dv-img class="report_bg" src="imgs/honours/证书背景.png"></dv-img>
				<div class="report_title">查看报告</div>
				<dv-img class="report_btn" src="imgs/p4/2f49ae58-5562-4499-a548-dba9f2b4b752.png" @click="closeReport"></dv-img>
				<iframe class="report_pdf" border="0" src=""></iframe>
			</div>

			<div id="testDiv"></div>

		</div>
	</div>
</template>

<script>
	import * as $ from 'jquery'
	import dataV from '../../bin/dataV'
	import dvImg from './dv-img'

	export default {
		data: function(){
			return {
						Id: 'CenterMapComponent',
						currentTime: dataV.DateTime.format('YYYY-mm-dd HH:MM:SS') + '　' + dataV.DateTime.getWeekCH(),
						ImgBaseUrl: dataV.Config.baseUrl.img,

						ProjectList: window.ApiData.index.ProjectList,
	
						currentPageLevel: dataV.Page.CurrentLevel,		// 大屏当前页面层级，1-全国、2-项目部
						currentArea: '全国区域',
						currentTunnel: dataV.Config.initPage.tunnel,
						currentDefect: dataV.Config.initPage.defect,

						currentProgram: dataV.Page.CurrentId,
						
						vrURL: dataV.Config.vr.url,

						goImg: '1a5858c1-80c8-4cfc-886b-72cca18c37fa',
						turnImg: '8cf42615-2c94-4ff4-a522-844a86f766fa',

						hasReport: false,
						hasRepairReport: false,
						hasRepairCase: false,

						WindowStatus: {
							isReportWindowOpen: false,
						},

						mapOffsetX: 390,
						xyScale: 0.76,
					}
		},
		props: [],
		created() {
			window[this.Id] =  this;
			window.dataV.MapComponent = this;
		},
		computed: { 
			
		},
		methods: {
			tellme(){
				console.log(event.offsetX, event.offsetY);
			},

			sync(){
				// $('#dv-map').css('opacity', 0)
				this.currentPageLevel = dataV.Page.CurrentLevel
				this.currentProgram = dataV.Page.CurrentId
			},

			viewReport(type){
				if(dataV.WebVR){
					if(dataV.WebVR.Report && type == 'report'){
						dataV.ModelWindows.close('缺陷报告');
						// this.sound4open.run(true);
						this.WindowStatus.isReportWindowOpen = true;
						$('.ReportViewer .report_pdf').attr('src', dataV.Config.baseUrl.img + dataV.WebVR.Report.url);
						$('.ReportViewer').css({
							width: 0,
							height: 0,
							left: '910px',
							top: '565px'
						}).show().animate({
							width: '735px',
							height: '935px',
							left: '610px',
							top: '95px'
						}, 500)
					}else if(dataV.WebVR.RepairReport && type == 'repair'){
						dataV.ModelWindows.close('缺陷报告');
						// this.sound4open.run(true);
						this.WindowStatus.isReportWindowOpen = true;
						$('.ReportViewer .report_pdf').attr('src', dataV.Config.baseUrl.img + dataV.WebVR.RepairReport.url);
						$('.ReportViewer').css({
							width: 0,
							height: 0,
							left: '910px',
							top: '565px'
						}).show().animate({
							width: '735px',
							height: '935px',
							left: '610px',
							top: '95px'
						}, 500)
					}else if(dataV.WebVR.RepairCase && type == 'repairCase'){
						dataV.ModelWindows.close('缺陷报告');
						// this.sound4open.run(true);
						this.WindowStatus.isReportWindowOpen = true;
						$('.ReportViewer .report_pdf').attr('src', dataV.Config.baseUrl.img + dataV.WebVR.RepairCase.url);
						$('.ReportViewer').css({
							width: 0,
							height: 0,
							left: '910px',
							top: '565px'
						}).show().animate({
							width: '735px',
							height: '935px',
							left: '610px',
							top: '95px'
						}, 500)
					}
				}
			},
			closeReport(){
				// this.sound4close.run(true);
				this.WindowStatus.isReportWindowOpen = false;
				$('.ReportViewer').animate({
					width: 0,
					height: 0,
					left: '910px',
					top: '565px'
				}, 500, function(){
					$('.ReportViewer').hide()
				})
			},
			
			hideVRBTN(){
				$('#webVRbox .bg_btn05').hide();
			},
			
			goBack(){
				dataV.Page.Toggle(this, 2, dataV.Page.CurrentId || window.ApiData.index.ProjectList[0].Id);
			},
			
			vrResetCamare(){
				if(dataV.WebVR){
					dataV.WebVR.resetCamare();
				}
			},
			
			toggleVR(){
				if(dataV.WebVR){
					dataV.WebVR.toggleWebVR();
				}
			},
			
			toggleDefect(defect){
				window.BottomTunnelComponent.toggleDefect({target: { title: defect}})
			},
			
			toggleBtnImg(command){
				switch(command){
					case 'go-left':
						this.goImg = '3bc7b399-f7a6-4ad6-aa2a-8e3a321542f1';
						break;
					case 'go-right':
						this.goImg = 'a66861e9-a3dc-459d-bba6-52574bc95377';
						break;
					case 'go-front':
						this.goImg = 'adae866e-1980-41c2-b98d-13a55d4720a6';
						break;
					case 'go-back':
						this.goImg = 'd8fac20a-5050-4863-b3d1-56ea284504fa';
						break;
					case 'go-default':
						this.goImg = '1a5858c1-80c8-4cfc-886b-72cca18c37fa';
						break;
					case 'turn-left':
						this.turnImg = 'a032f1e1-9b89-4fc7-a96d-e495a0936ace';
						break;
					case 'turn-right':
						this.turnImg = 'f3f30a7b-87a1-47da-9c5f-2f1f08805219';
						break;
					case 'turn-up':
						this.turnImg = '02fdf88c-a08c-4886-8094-f47550aabbab';
						break;
					case 'turn-down':
						this.turnImg = 'f99c0a63-abab-4ff9-83a2-6f479f13a315';
						break;
					case 'turn-default':
						this.turnImg = '8cf42615-2c94-4ff4-a522-844a86f766fa';
						break;
					default:
						this.goImg = '1a5858c1-80c8-4cfc-886b-72cca18c37fa';
						this.turnImg = '8cf42615-2c94-4ff4-a522-844a86f766fa';
				}
			},
			
			selectProgram(){
				// 跟随按钮，切换地图右侧的项目文字列表
				// var dvRightMenu = this.$parent.$children.filter((item) => { return item.$el.id == 'dv-right-menu' })[0];
				dataV.Page.Toggle(this, 2, $(event.target).data('id'));
			}
		},
		watch: {
			currentTunnel(){
				this.vrURL += '?now=' + new Date()
			}
		},
		mounted: function(){
			// 人员信息配乐
			// this.sound4toggle = dataV.TaskSystem.sound.copy('sounds/10808.wav');
			// this.sound4menu = dataV.TaskSystem.sound.copy('sounds/696.wav');
			// this.sound4menuhover = dataV.TaskSystem.sound.copy('sounds/02.mp3');
			// this.sound4open = dataV.TaskSystem.sound.copy('sounds/9675.wav');
			// this.sound4close = dataV.TaskSystem.sound.copy('sounds/9676.wav');
			// this.sound4fly = dataV.TaskSystem.sound.copy('sounds/9073.wav');

			// 注册弹出窗口
			dataV.ModelWindows.regist('缺陷报告', this.WindowStatus, 'isReportWindowOpen', true, this.closeReport);

			// function getBase64Image(img) {
			// 	var canvas = document.createElement("canvas");
			// 	canvas.width = img.width;
			// 	canvas.height = img.height;
			// 	var ctx = canvas.getContext("2d");
			// 	ctx.drawImage(img, 0, 0, img.width, img.height);
			// 	var dataURL = canvas.toDataURL("image/png");
			// 		return dataURL;
			// 	//return dataURL.replace("data:image/png;base64,", ""); 
			// }

			// var img = new Image();
			// // img.setAttribute('crossOrigin', 'anonymous');
			// img.onload =function() { 
			// 	$('#testDiv').append(img)
			// 		var data = (function getBase64Image(_img) {
			// 		var canvas = document.createElement("canvas");
			// 		canvas.width = _img.width;
			// 		canvas.height = _img.height;
			// 		var ctx = canvas.getContext("2d");
			// 		ctx.drawImage(_img, 0, 0, _img.width, _img.height);
			// 		var dataURL = canvas.toDataURL("image/png");
			// 			return dataURL;
			// 		//return dataURL.replace("data:image/png;base64,", ""); 
			// 	})(img); 
			// 	console.log('.....................', data); 
			// } 
			// // img.src = 'https://cr12hh.sxaliyun.com/Resource/TestFaultShowPic/8e905bc5-586f-4251-9e02-33c816be6507/79a460b0-1e64-47bd-a706-f88fa2777d20.png';
			// img.src = 'imgs/p4/2f49ae58-5562-4499-a548-dba9f2b4b752.png';
		},

		components: {
			'dv-img': dvImg,
		}
		
	}
</script>
