<style>
  #dv-right-program { position: absolute; right: -1100px; top: 0; width: 560px; }
  #dv-right-program .time { position: absolute; right: 45px; top: 50px; width: 400px; text-align: right; font-size: 18px; font-weight: 600; font-style: normal; color: rgb(0, 112, 216); }

  #dv-right-program .text{ position: absolute; left: 120px; top: 205px; font-size: 16px; font-weight: bold; width: 810px; }
  #dv-right-program .text span{ color: #00ffff; font-family: 'Work Sans', sans-serif, "microsoft yahei"; }/* 'Teko' */

  #p2 { position: absolute; left: -25px; top: 0; font-weight: bold; font-family: 'Work Sans', sans-serif, "microsoft yahei"; }
  #p2 .bg02{ position: absolute; left: 150px; top: 100px; width: 390px; height: 220px }
  #p2 .bg03{ position: absolute; left: 150px; top: 339px; width: 390px; height: 220px }
  #p2 .bg04{ position: absolute; left: 150px; top: 578px; width: 390px; height: 220px }
  #p2 .bg05{ position: absolute; left: 150px; top: 817px; width: 390px; height: 220px }

  #p2 .bg02-title{ position: absolute; left: 160px; top: 110px; }
  #p2 .bg04-title{ position: absolute; left: 160px; top: 349px; }
  #p2 .bg05-title{ position: absolute; left: 160px; top: 588px; }
  #p2 .bg03-title{ position: absolute; left: 160px; top: 827px; }
  #p2 .label_trend_01{ position: absolute; left: 172px; top: 111px; width: 200px; }
  #p2 .label_trend_03{ position: absolute; left: 172px; top: 350px; width: 200px; }
  #p2 .label_trend_04{ position: absolute; left: 172px; top: 589px; width: 200px; }
  #p2 .label_trend_02{ position: absolute; left: 172px; top: 828px; width: 200px; }
  
  #p2 .echarts #chart_p2_01{ position: absolute; left: 165px; top: 140px; width: 360px; height: 165px; /* border: 1px solid red; */ }
  #p2 .echarts #chart_p2_03{ position: absolute; left: 165px; top: 379px; width: 360px; height: 165px; /* border: 1px solid red; */ }
  #p2 .echarts #chart_p2_04{ position: absolute; left: 165px; top: 618px; width: 360px; height: 165px; /* border: 1px solid red; */ }
  #p2 .echarts #chart_p2_02{ position: absolute; left: 165px; top: 857px; width: 360px; height: 165px; /* border: 1px solid red; */ }

</style>

<template>
  <div id="dv-right-program">
    <i class="time" v-text="currentTime"></i>

    <div class="page" id="p2">
      <dv-img class="bg02" src="imgs/p3/0814aff0-a250-46c9-99d7-565b05d6ab84.png"></dv-img>
      <dv-img class="bg03" src="imgs/p3/0814aff0-a250-46c9-99d7-565b05d6ab84.png"></dv-img>
      <dv-img class="bg04" src="imgs/p3/0814aff0-a250-46c9-99d7-565b05d6ab84.png"></dv-img>
      <dv-img class="bg05" src="imgs/p3/0814aff0-a250-46c9-99d7-565b05d6ab84.png"></dv-img>
      <dv-img class="bg02-title" src="imgs/p3/f7522c50-0cbe-4384-b554-fdec0c1bfde5.png"></dv-img>
      <dv-img class="bg03-title" src="imgs/p3/6707e5e0-5929-4452-b4e5-684c4199da79.png"></dv-img>
      <dv-img class="bg04-title" src="imgs/p3/48ce1d6c-2b7e-427a-bf12-bf5ccf25d2b8.png"></dv-img>
      <dv-img class="bg05-title" src="imgs/p3/f09ce625-c05b-4e73-a318-a0b95a72b81f.png"></dv-img>
      <div class="label_trend_01">衬砌厚度不足发展趋势</div>
      <div class="label_trend_02">空洞、不密实问题发展趋势</div>
      <div class="label_trend_03">钢筋缺陷问题发展趋势</div>
      <div class="label_trend_04">防裂钢筋网问题发展趋势</div>
      <div class="echarts">
        <div id="chart_p2_01"></div>
        <div id="chart_p2_02"></div>
        <div id="chart_p2_03"></div>
        <div id="chart_p2_04"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import dvImg from "./dv-img.vue"
import dataV from '../../bin/dataV'

export default {
  data() {
    return {
      Id: 'RightProgramComponent',
      currentTime: dataV.DateTime.format('YYYY-mm-dd HH:MM:SS') + '　' + dataV.DateTime.getWeekCH(),
      pagesize: 5,				// 项目、隧道、缺陷列表显示记录数
      currentPage: 1,				// 项目、隧道、缺陷列表当前页码
      currentPageTimer: null,
      contrastIndex: 1, 			// 项目列表 或 隧道列表，对比维度：1-缺陷率对比，2-整改合格率对比
      pageToggleKey: true,		// 是否允许列表页自动翻页

      echartsTimer: null,
      charts: {},

      CrackLength: 0,
      CrackRate: 0,
      DesignLength: 0,
      FaultCount1: 0,
      FaultCount2: 0,
      FaultCount3: 0,
      FaultCount4: 0,
      FaultCount5: 0,
      FaultCount6: 0,
      FaultRate1: 0,
      FaultRate2: 0,
      FaultRate3: 0,
      FaultRate4: 0,
      FaultRate5: 0,
      FaultRate6: 0,
      FaultTrend1: [],
      FaultTrend2: [],
      FaultTrend3: [],
      FaultTrend4: [],
      ProjectSimpleInfo: {Name: "", SiteName: "", Address: "", Describe: ""},
      TestLength: 0,
      TestRate: 0,
      TotalFaultBadCount: 0,
      TotalFaultBadFixRate: 0,
      TotalFaultBadRate: 0,
      TotalFaultCount: 0,
      TotalFaultFixRate: 0,
      TotalFaultRate: 0,
      TunnelCompareFault: [],
      TunnelCompareFix: [],
      TunnelCount: 0,
      VideoList: [],

      needCompareIds: []

    }
  },
  props: [],
  components: {
    "dv-img": dvImg,
    // 'dv-bar-2d': dvBar2d,
  },
  methods: {

    p2trend(){
      var that = this;
      //      
      if(!this.charts['chart_01']) this.charts['chart_01'] = echarts.init(document.getElementById('chart_p2_01'));
      this.charts['chart_01'].setOption({
          tooltip: { trigger: 'axis' }, grid: { left: '30', right: '0', bottom: '20', top: '10', },
          xAxis: { show: true, type: 'category', boundaryGap: false, data: that.FaultTrend1.map(item => item.Name) },
          yAxis: { show: true, type: 'value', splitLine: { show: false } },
          series: [
              { name: '衬砌厚度不足', type: 'line', data: that.FaultTrend1.map(item => item.FaultCount), smooth: true, showSymbol: false, hoverAnimation: false, lineStyle: { color: "#b30306", width: 0.5 }, areaStyle: { color: "#b30306", opacity: 0.3 } }
          ]
      });
      if(!this.charts['chart_02']) this.charts['chart_02'] = echarts.init(document.getElementById('chart_p2_02'));
      this.charts['chart_02'].setOption({
          tooltip: { trigger: 'axis' }, grid: { left: '30', right: '0', bottom: '20', top: '10', },
          xAxis: { show: true, type: 'category', boundaryGap: false, data: that.FaultTrend2.map(item => item.Name) },
          yAxis: { show: true, type: 'value', splitLine: { show: false } },
          series: [
              { name: '空洞、不密实', type: 'line', data: that.FaultTrend4.map(item => item.FaultCount), smooth: true, showSymbol: false, hoverAnimation: false, lineStyle: { color: "#33CBE9", width: 0.5 }, areaStyle: { color: "#33CBE9", opacity: 0.3 } }
          ]
      });
      if(!this.charts['chart_03']) this.charts['chart_03'] = echarts.init(document.getElementById('chart_p2_03'));
      this.charts['chart_03'].setOption({
          tooltip: { trigger: 'axis' }, grid: { left: '30', right: '0', bottom: '20', top: '10', },
          xAxis: { show: true, type: 'category', boundaryGap: false, data: that.FaultTrend3.map(item => item.Name) },
          yAxis: { show: true, type: 'value', splitLine: { show: false } },
          series: [
              { name: '钢筋缺失问题', type: 'line', data: that.FaultTrend2.map(item => item.FaultCount), smooth: true, showSymbol: false, hoverAnimation: false, lineStyle: { color: "#FFBC09", width: 0.5 }, areaStyle: { color: "#FFBC09", opacity: 0.3 } }
          ]
      });
      if(!this.charts['chart_04']) this.charts['chart_04'] = echarts.init(document.getElementById('chart_p2_04'));
      this.charts['chart_04'].setOption({
          tooltip: { trigger: 'axis' }, grid: { left: '30', right: '0', bottom: '20', top: '10', },
          xAxis: { show: true, type: 'category', boundaryGap: false, data: that.FaultTrend4.map(item => item.Name) },
          yAxis: { show: true, type: 'value', splitLine: { show: false } },
          series: [
              { name: '防裂钢筋网问题', type: 'line', data: that.FaultTrend3.map(item => item.FaultCount), smooth: true, showSymbol: false, hoverAnimation: false, lineStyle: { color: "#CDCDCD", width: 0.5 }, areaStyle: { color: "#FFFFFF", opacity: 0.3 } }
          ]
      });

      // 轮循tooltip
      if(this.echartsTimer) clearInterval(this.echartsTimer)
      var index = 0; //播放所在下标
      this.echartsTimer = setInterval(function() {
          that.charts['chart_01'].dispatchAction({ type: 'showTip', seriesIndex: 0, dataIndex: index });
          that.charts['chart_02'].dispatchAction({ type: 'showTip', seriesIndex: 0, dataIndex: index });
          that.charts['chart_03'].dispatchAction({ type: 'showTip', seriesIndex: 0, dataIndex: index });
          that.charts['chart_04'].dispatchAction({ type: 'showTip', seriesIndex: 0, dataIndex: index });
          index++;
          if(index >= that.FaultTrend4.length) {
              index = 0;
          }
      }, 3000);

    },

  },
  computed: {
  },
  watch: {

    FaultTrend1() {
      this.p2trend()
    }

  },
  created() {
			window[this.Id] =  this;
  },
  mounted() {
  },
};
</script>
