import _Vue from 'vue'
import App from './App.vue'
import VueAxiosPlugin from 'vue-axios-plugin'
import api from "./api/index";
import * as $ from 'jquery'
import vuescroll from 'vuescroll'
import 'vuescroll/dist/vuescroll.css'
import dataV from './bin/dataV'

window.ApiData = {};

_Vue.config.productionTip = false

_Vue.use(VueAxiosPlugin, {
  reqHandleFunc: config => {
    config.headers['X-Token'] = window.ApiData.token
    return config
  },
  reqErrorFunc: error => Promise.reject(error),
  resHandleFunc: response => response.data,
  resErrorFunc: error => {
    $('#app').html('服务器响应失败，请联系管理员！');
    Promise.reject(error)
  }
}).use(api).use(vuescroll)

const url = require('url');
const query = require('querystring');
const { code } = query.parse(url.parse(window.location.href).query);

const Vue = new _Vue({ render: h => h(App) })
const loadVueUI = () => { Vue.$mount('#app') }

const Funs = [
  { key: 'token', apiName: 'token', query: { randomCode: code || 'Fixe18425f42' } },
  { key: 'index', apiName: 'index' },
  // { apiName: 'program' },
]
let FunIndex = -1;
let ApiErr = false;

const getApiDate = () => {
  if (!Funs[FunIndex + 1]) {
    console.log(window.ApiData)
    if(!ApiErr) loadVueUI()
    return;
  }
  FunIndex++
  const params = Funs[FunIndex]
  // $('#app').append('<div id="' + params.apiName + '" style="font-size: 18px">[' + new Date().toLocaleString() + '] 获取 ' + params.apiName + ' => <span></span></div>');
  Vue.$api.request(Object.assign({ Vue }, params)).then(response => {
    if (response.state === 'success') {
      // $('#app #' + params.apiName + ' span').html('成功')
      if (params.cb) params.cb(response.data)
    } else {
      // $('#app #' + params.apiName + ' span').html('失败：' + response.message)
      ApiErr = true
    }
    window.ApiData[params.key] = (() => { try { return JSON.parse(response.data) } catch (e) { return response.data } })()
    getApiDate()
  })
}

getApiDate()

// 键盘操作，主要针对web3D窗口，将焦点转移到隧道模型窗口上，并增加部分键盘操作
window.addEventListener( 'keydown', function(e){
  // 当按下回车键时，打开语音模拟输入窗口

  // 其他按钮响应：全屏、模型回到初始位置
  const keyValue = e.code || dataV.Config.KEYCODE_TO_CODE[e.keyCode];
  if (dataV.Config.KEYS.indexOf(keyValue) !== -1) {
    $('#dv-map .vrbrowser iframe').focus();
  }
} , false );
