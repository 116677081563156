<style scoped>
    .hide { display: none; }

    .dv-bottom-index { position: absolute; bottom: -800px; left: 500px; pointer-events: none; }
    .dv-bottom-index .top3 { position: absolute; bottom: 260px; left: 0; width: 260px; height: 170px; }
    .dv-bottom-index .top3 .transroads { position: absolute; top: 0; left: 20px; display: none; }
    .dv-bottom-index .top3 .transroads.show { display: block; }
    .dv-bottom-index .top3 .transroads .sign { position: absolute; top: 20px; left: 0; }
    .dv-bottom-index .top3 .transroads .caption { position: absolute; top: 20px; left: 40px; font-size: 16px; font-weight: bold; width: 190px; }
    .dv-bottom-index .top3 .transroads .title { position: absolute; top: 55px; left: 0; font-size: 16px; font-weight: bold; width: 220px; overflow: hidden; color: #37FDFE }
    .dv-bottom-index .top3 .transroads .bugrate { position: absolute; top: 90px; left: 0; font-size: 14px; font-weight: bold; width: 220px; }
    .dv-bottom-index .top3 .transroads .modrate { position: absolute; top: 115px; left: 0; font-size: 14px; font-weight: bold; width: 220px; }
    .dv-bottom-index .top3 .transroads .bugrate .label,
    .dv-bottom-index .top3 .transroads .modrate .label { position: absolute; top: 0; left: 0; text-align: justify; text-align-last:justify; height: 24px; width: 85px; }
    .dv-bottom-index .top3 .transroads .bugrate .label:after,
    .dv-bottom-index .top3 .transroads .modrate .label:after { display:inline-block; content:''; overflow:hidden; width:100%; height:0; }
    .dv-bottom-index .top3 .transroads .bugrate .bar,
    .dv-bottom-index .top3 .transroads .modrate .bar { position: absolute; top: 2px; left: 82px; width: 85px; }
    .dv-bottom-index .top3 .transroads .bugrate .value,
    .dv-bottom-index .top3 .transroads .modrate .value { position: absolute; top: 2px; right: 0; color: #37FDFE }
    .dv-bottom-index .top3 .pageBtns { position: absolute; left: 0; top: 145px; width: 260px; text-align: center; }
    .dv-bottom-index .top3 .pageBtns .pageIcon{ display: inline-block; margin: 0 2px; width: 8px; cursor: pointer; pointer-events: all; }

    .dv-bottom-index .dataList { position: absolute; left: 0; bottom: 215px; width: 950px; height: 500px }

    /* 项目数据列表 */
    .dv-bottom-index .dataList .bg-4{ position: absolute; left: 0; top: 475px; width: 114px; height: 36px; cursor: pointer; pointer-events: all; }
    .dv-bottom-index .dataList .bg-5{ position: absolute; left: 119px; top: 475px; width: 150px; height: 36px; cursor: pointer; pointer-events: all; }

    .dv-bottom-index .dataList .bg-6{ position: absolute; left: 0; top: 524px; width: 100%; height: 36px; }
    .dv-bottom-index .dataList .bg-14{ position: absolute; left: 0; top: 558px; width: 100%; height: 8px; }

    .dv-bottom-index .dataList .bg-line{ position: absolute; left: -22px; top: 0; width: 100%; height: 26px; }
    .dv-bottom-index .dataList .sign{ position: absolute; left: -22px; top: 0; background: url('/imgs/p101/sign02.png') no-repeat left top; width: 28px; height: 30px; background-size: 28px; cursor: pointer; pointer-events: all; }
    .dv-bottom-index .dataList .sign.selected{ background-image: url('/imgs/p101/sign01.png') }
    .dv-bottom-index .dataList .filter{ filter: grayscale(80%) }

    /* 项目数据列表 */
    .dv-bottom-index .dataList .item-3{ position: absolute; left: 17px; top: 480px; font-size: 16px; font-weight: bold; line-height: 170%; cursor: pointer; }
    .dv-bottom-index .dataList .item-4{ position: absolute; left: 138px; top: 480px; font-size: 16px; font-weight: bold; line-height: 170%; cursor: pointer; }

    .dv-bottom-index .dataList .pageBtns{ position: absolute; left: 715px; top: 490px; width: 220px; text-align: right; }
    .dv-bottom-index .dataList .pageBtns .pagebtn{ margin: 0 3px; cursor: pointer; cursor: pointer; pointer-events: all; }

    .dv-bottom-index .dataList .item-5 { position: absolute; left:  70px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
    .dv-bottom-index .dataList .item-6 { position: absolute; left: 210px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
    .dv-bottom-index .dataList .item-7 { position: absolute; left: 310px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
    .dv-bottom-index .dataList .item-8 { position: absolute; left: 396px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
    .dv-bottom-index .dataList .item-9 { position: absolute; left: 515px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
    .dv-bottom-index .dataList .item-10{ position: absolute; left: 625px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
    .dv-bottom-index .dataList .item-11{ position: absolute; left: 735px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
    .dv-bottom-index .dataList .item-12{ position: absolute; left: 845px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }

    .dv-bottom-index .dataList .ProgramList { position: absolute; left: -10px; top:565px; width: 960px; font-size: 15px; height: 150px; overflow: hidden; }
    .dv-bottom-index .dataList .ProgramList .program { position: absolute; left: 30px; width: 100%; }
    .dv-bottom-index .dataList .ProgramList .program span:nth-of-type(1){ position: absolute; left: 20px; top: 3px; color: #ffffff; cursor: pointer; pointer-events: all; }
    .dv-bottom-index .dataList .ProgramList .program span:nth-of-type(2){ position: absolute; left: 185px; top: 3px; width: 65px; text-align: right; }
    .dv-bottom-index .dataList .ProgramList .program span:nth-of-type(3){ position: absolute; left: 200px; top: 3px; width: 145px; text-align: right; }
    .dv-bottom-index .dataList .ProgramList .program span:nth-of-type(4){ position: absolute; left: 380px; top: 3px; width: 60px; text-align: right; }
    .dv-bottom-index .dataList .ProgramList .program .bar3d1{ position: absolute; left: 490px; top: 5px; width: 100px; }
    .dv-bottom-index .dataList .ProgramList .program .bar3d2{ position: absolute; left: 600px; top: 5px; width: 100px; }
    .dv-bottom-index .dataList .ProgramList .program .bar3d3{ position: absolute; left: 710px; top: 5px; width: 100px; }
    .dv-bottom-index .dataList .ProgramList .program .bar3d4{ position: absolute; left: 820px; top: 5px; width: 100px; }
    .dv-bottom-index .dataList .ProgramList .program .text span{ font-weight: normal; }

</style>

<template>
    <div class="dv-bottom-index">
        <div class="top3">
            <dv-img src="../imgs/index/标杆项目部BG.png" />
            <div v-for="(road, index) in top3" :key="index" :class="'transroads' + (index == topIndex ? ' show' : '')">
                <dv-img class="sign" :src="'../imgs/index/TOP' + (index + 1) + '.png'" />
                <div class="caption">标杆项目部</div>
                <div class="title" v-text="road.Name" :data-title="road.Name" :data-id="road.Id" @click="link2Program"></div>
                <div class="bugrate">
                    <span class="label">总缺陷率：</span>
                    <span class="bar">
                        <dv-bar-2d :value="road.TotalFaultRate" height="16px" barcolorh="0" during="3"></dv-bar-2d>
                    </span>
                    <span class="value" v-text="road.TotalFaultRate + '%'"></span>
                </div>
                <div class="modrate">
                    <span class="lable">整改合格率：</span>
                    <span class="bar">
                        <dv-bar-2d :value="road.TotalFixRate" height="16px" barcolorh="220" during="3"></dv-bar-2d>
                    </span>
                    <span class="value" v-text="road.TotalFixRate + '%'"></span>
                </div>
            </div>
            <div class="pageBtns">
                <dv-img class="pageIcon" v-for="(item, index) in top3" :key="'pageIcon_' + index" @mouseover="togglePage" :src="'imgs/p2/' + (topIndex == index ? '06f15087-c767-45be-977e-2e00800cfac6' : '2dc0cea5-876d-4e53-9c26-10debca2d21b') + '.png'" :data-index="index"></dv-img>
            </div>
        </div>

                
        <div class="dataList">
            <!-- 项目数据列表 -->
            <dv-img :class="'bg-4' + (contrastIndex == 1 ? '' : ' filter')" @click="toggleContrastBtn" data-index="1" src="imgs/p2/06a794e5-ca98-4ef4-92e5-94c66d96e2d0.png"></dv-img>
            <dv-img :class="'bg-5' + (contrastIndex == 1 ? ' filter' : '')" @click="toggleContrastBtn" data-index="2" src="imgs/p2/06a794e5-ca98-4ef4-92e5-94c66d96e2d0.png"></dv-img>
            <dv-img class="bg-6" src="imgs/p2/06a794e5-ca98-4ef4-92e5-94c66d96e2d0.png"></dv-img>
            <dv-img class="bg-14" src="imgs/p2/line.png"></dv-img>

            <!-- 项目数据列表 -->
            <div class="item-3" @click="toggleContrastBtn" data-index="1">缺陷率对比</div>
            <div class="item-4" @click="toggleContrastBtn" data-index="2">整改合格率对比</div>
            <div class="item-5">项目名称</div>
            <div class="item-6">检测覆盖率</div>
            <div class="item-7" v-text="contrastIndex == 1 ? '总缺陷率' : '已整改率'"></div>
            <div class="item-8" v-text="contrastIndex == 1 ? '7天验证超时' : '7天验证超时'"></div>
            <div class="item-9">厚度不足</div>
            <div class="item-10">钢筋缺失</div>
            <div class="item-11">防裂钢筋网</div>
            <div class="item-12">空洞、不密实</div>
            
            <div class="pageBtns">
                <dv-img class="pagebtn" v-for="pageIndex in (Math.floor(getContrast.length / pagesize) + (getContrast.length % pagesize > 0 ? 1 : 0))" :key="'page_' + pageIndex" @mouseover="selectPage" :data-index="pageIndex" :src="'imgs/p2/' + (currentPage == pageIndex ? '06f15087-c767-45be-977e-2e00800cfac6' : '2dc0cea5-876d-4e53-9c26-10debca2d21b') + '.png'"></dv-img>
            </div>

            <div style="position: absolute; left: 280px; top: 476px; line-height: 34px; padding: 0 20px; border: 1px solid #2CF7FE; background-color: #083866; color: #2CF7FE; cursor: pointer; pointer-events: all;" @click="ComparePrograms">数据对比</div>
                
            <div class="ProgramList" :style="'height: ' + pagesize * 30 + 'px'" @mouseover="pageMouseOver" @mouseleave="pageMouseLeave">
            <div class="program" v-for="(item, index) in getContrast" :key="'pgRow_' + index" :style="'top: ' + (0 + 30 * (index)) + 'px'">
                <dv-img class="bg-line" src="imgs/p2/line-bg.png"></dv-img>
                <div :class="'sign' + (needCompareIds.indexOf(item.Id) > -1 ? ' selected' : '')" @click="toggleSelect(item.Id)"></div>
                <span v-text="item.Name" @click="selectProgram(2, item.Id)"></span>
                <span v-text="item.TestRate + '%'"></span>
                <span v-text="item.DataRate + '%'"></span>
                <span v-text="item.OverTime7Count + '处'"></span>
                <dv-bar-2d class="bar3d1" :value="item.DataRate1" height="16px" :barcolorh="contrastIndex == 1 ? '0' : '120'" during="3" showtext="true"></dv-bar-2d>
                <dv-bar-2d class="bar3d2" :value="item.DataRate2" height="16px" :barcolorh="contrastIndex == 1 ? '30' : '120'" during="3" showtext="true"></dv-bar-2d>
                <dv-bar-2d class="bar3d3" :value="item.DataRate3" height="16px" :barcolorh="contrastIndex == 1 ? '0' : '120'" :barcolors="contrastIndex == 1 ? '5%' : '100%'" during="3" showtext="true"></dv-bar-2d>
                <dv-bar-2d class="bar3d4" :value="item.DataRate4" height="16px" :barcolorh="contrastIndex == 1 ? '190' : '120'" during="3" showtext="true"></dv-bar-2d>
            </div>
            <div class="program" v-for="index in (getContrast.length < pagesize ? pagesize - getContrast.length : 0)" :style="'top: ' + (0 + 30 * (index + getContrast.length - 1)) + 'px'" :key="'pgLine_' + index">
                <dv-img class="bg-line" src="imgs/p2/line-bg.png"></dv-img>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as $ from 'jquery'
import dataV from '../../bin/dataV'
import dvImgVue from './dv-img.vue';
import dvBar2dVue from './dv-bar-2d.vue';

export default {
    data () {
        return {
            Id: 'BottomIndexComponent',
            topIndex: 0,
            top3: window.ApiData.index.SignProjectList,

            ProjectCompareFault: window.ApiData.index.ProjectCompareFault,
            ProjectCompareFix: window.ApiData.index.ProjectCompareFix,

            currentDefect: dataV.Config.initPage.defect,

            pagesize: 5,				// 项目、隧道、缺陷列表显示记录数
            currentPage: 1,				// 项目、隧道、缺陷列表当前页码
            currentPageTimer: null,
            contrastIndex: 1, 			// 项目列表 或 隧道列表，对比维度：1-缺陷率对比，2-整改合格率对比
            pageToggleKey: true,		// 是否允许列表页自动翻页
            maxPage: dataV.Config.initPage.maxPage,

            needCompareIds: []            
        }
    },
    components: {
        'dv-img': dvImgVue,
        'dv-bar-2d': dvBar2dVue
    },
    created() {
        window[this.Id] =  this;
    },
    methods: {
                    
        link2Program () {
            dataV.Page.Toggle(this, 2, $(event.target).data('id'));
        },

        // 切换
        togglePage (index) {
            this.topIndex = index || $(event.target).data('index');
        },
        
        toggleSelect(Id){
            const index = this.needCompareIds.indexOf(Id)
            if(index > -1) this.needCompareIds = this.needCompareIds.slice(0, index).concat(this.needCompareIds.slice(index + 1))
            else this.needCompareIds.push(Id)
            // console.log(this.needCompareIds)
        },

        ComparePrograms(){
            const Ids = this.needCompareIds.filter(item => item).join(',')
            if(Ids) window.CenterListComponent.ComparePrograms(Ids)
            else dataV.ModelWindows.close()
        },

        selectProgram(level, id){
            dataV.Page.Toggle(this, level, id)
        },

        selectPage(){		// 数据列表，手动换页
            this.currentPage = $(event.target).data('index');
            this.changePage(this.getContrast.length);
        },

        changePage(dataLength){
        var that = this;
        //
        if(this.currentPageTimer) clearInterval(this.currentPageTimer);
        var defectPageCount = Math.floor(dataLength / this.pagesize) + (dataLength % this.pagesize > 0 ? 1 : 0);
        if(defectPageCount > 1) {
            if(this.currentPageTimer) {
            clearInterval(this.currentPageTimer)
            this.currentPageTimer = null
            }
            this.currentPageTimer = setInterval(function(){
            if(that.pageToggleKey){
                var newpage = that.currentPage + 1;
                if(newpage > defectPageCount) newpage = 1;
                that.currentPage = newpage;
            }
            }, 5000);
        }
        },

        toggleContrastBtn(){
            this.contrastIndex = $(event.target).data('index');
        },
        pageMouseOver(){
            this.pageToggleKey = false;
        },
        pageMouseLeave(){
            this.pageToggleKey = true;
        },

    },
    computed: {
        defects(){
            return this.tunnelInfoPatch.defects.filter((item)=>{ return item.type == this.currentDefect })
        },

        getContrast () {  // 项目的对比列表
            if(this.contrastIndex == 1){  // 返回 缺陷率对比列表
            return this.ProjectCompareFault
            }else{        // 返回 整改合格率对比列表
            return this.ProjectCompareFix
            }
        }

    },
    watch: {

        currentPage(){
            var targetTop = (this.currentPage - 1) * this.pagesize * 30;
            $('.dv-bottom-index .dataList .ProgramList').animate({
                scrollTop: targetTop + 'px'
            }, 'slow');
        },

    },

};
</script>