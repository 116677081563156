<style>
#dv-footer {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 150px;
  background: url("../../../public/imgs/foot.png") center bottom no-repeat;
  background-size: 150%;
}
</style>

<template>
  <div id="dv-footer"></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: [],
  methods: {},
  mounted() {},
  components: {},
};
</script>
