<style>
	.progress3d-box {
	--width: 100%;
	--height: 30px;
	--thickrate: 4;
	--shadowheight: calc( var(--height) / var(--thickrate) ); 
	--backheight: calc( var(--height) - var(--shadowheight) );
	--backwidth: calc( var(--width) - var(--shadowheight) ); 
	
	--bar-percent: 0.8;
	--bar-not-zero: 1;
	--bar-width: calc( var(--bar-percent) * var(--backwidth) );
	--bar-hsl-h: 200;
	--bar-hsl-s: 80%;
	--bar-hsl-alpha: 1;
	--bar-color: hsl(var(--bar-hsl-h), var(--bar-hsl-s), 50%, var(--bar-hsl-alpha));
	--bar-color-light: hsl(var(--bar-hsl-h), var(--bar-hsl-s), 83%, var(--bar-hsl-alpha));
	--bar-color-heightlight: hsl(var(--bar-hsl-h), var(--bar-hsl-s), 87%, var(--bar-hsl-alpha));
	--bar-color-dark: hsl(var(--bar-hsl-h), var(--bar-hsl-s), 40%, var(--bar-hsl-alpha));
	
	--font-size: calc( var(--backheight) / 4 * 3 );
	
	--animation-time: 1s;
	
	position: relative;
	width: calc(var(--backwidth) + var(--shadowheight));
	height: var(--height);
	}
	
	.progress3d-bg-back {
	width: var(--backwidth); 
	height: var(--backheight);
	background-color: #000000;
	position: absolute;
	left: var(--shadowheight); 
	top: 0;
	}
	
	.progress3d-bg-bottom {
	width: var(--backwidth); 
	height: var(--shadowheight);
	background-color: #3e3e3e;
	transform: skewX(-45deg);
	position: absolute;
	left: calc( var(--shadowheight) / 2 ); 
	top: var(--backheight);
	}
	
	.progress3d-bg-bottom.flatBottom {
	background-color: #f9f9f9;
	transform: skewX(0deg);
	position: absolute;
	left: var(--shadowheight); 
	}
	
	@keyframes progress3d-bar-animation-Width {
	0%   { width: 0 }
	70%  { width: var(--bar-width) }
	100% { width: var(--bar-width) }
	}
	
	@keyframes progress3d-bar-animation-left {
	0%   { left: 0 }
	70%  { left: var(--bar-width) }
	100% { left: var(--bar-width) }
	}
	
	
	.progress3d-bar-top {
	width: var(--bar-width);
	animation: progress3d-bar-animation-Width var(--animation-time) ease-out infinite;
	height: var(--shadowheight);
	background: linear-gradient(to top, var(--bar-color-heightlight), var(--bar-color-light));
	-webkit-transform: skewX(-45deg);
	transform: skewX(-45deg);
	position: absolute;
	left: calc( var(--shadowheight) / 2 ); 
	top: 0;
	}
	
	.progress3d-bar-front {
	width: var(--bar-width);
	animation-delay:5s;
	-webkit-animation-delay:5s; /* Safari 和 Chrome */
	animation: progress3d-bar-animation-Width var(--animation-time) ease-out infinite;
	height: calc( var(--backheight) - 1px * var(--bar-not-zero) );
	background: linear-gradient(to bottom, var(--bar-color-light), var(--bar-color));
	border-top: calc(1px * var(--bar-not-zero)) solid var(--bar-color-heightlight);
	position: absolute;
	left: 0; 
	top: var(--shadowheight);
	}
	
	.progress3d-bar-right {
	width: calc( var(--shadowheight) * var(--bar-not-zero) - 1px * var(--bar-not-zero) );
	height: calc( var(--backheight) * var(--bar-not-zero) - 1px * var(--bar-not-zero) );
	background: linear-gradient(160deg, var(--bar-color-light), var(--bar-color-dark));
	border-left: calc(1px * var(--bar-not-zero)) solid var(--bar-color-light);
	border-top: calc(1px * var(--bar-not-zero)) solid var(--bar-color-light);
	transform: skewY(-45deg);
	position: absolute;
	left: var(--bar-width);
	animation-delay:5s;
	-webkit-animation-delay:5s; /* Safari 和 Chrome */
	animation: progress3d-bar-animation-left var(--animation-time) infinite ease-out;
	top: calc( var(--shadowheight) / 2 );
	}
	
	.progress3d-text {
	font-size: calc( var(--font-size) );
	line-height: calc( var(--backheight) );
	position: absolute;
	right: calc( var(--shadowheight) / 2 + 5px ); 
	top: calc( var(--shadowheight) );
	padding: 0;
	color: #fff;
	text-shadow: 3px 3px 3px #000;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: var(--backwidth);
	}
	.progress3d-text.left {
	right: auto;
	left: calc( var(--shadowheight) / 2 + 5px ); 
	}
</style>

<template>
	<div :id="id" class="progress3d-box" :style="'--animation-time: ' + (during || 1) + 's; --height: ' + (height || '20px') + '; --bar-hsl-h: ' + (barcolorh || 210) + '; --bar-hsl-s: ' + (barcolors || '80%') + '; --bar-percent: ' + (scaledValue ? (scaledValue < 0 ? 0 : (scaledValue > 100 ? 1 : scaledValue / 100)) : 0) + '; --bar-not-zero: ' + (scaledValue ? (scaledValue <= 0 ? (showzero ? 1 : 0) : 1) : (showzero ? 1 : 0)) + '; --thickrate: ' + (thickrate ? thickrate : 4) + '; ' + (fontsize ? '--font-size: ' + fontsize + '; ' : '')">
		<div class="progress3d-bg-back"></div>
		<div :class="'progress3d-bg-bottom ' + (flatbottom ? 'flatbottom' : '')"></div>
		<div class="progress3d-bar-top"></div>
		<div class="progress3d-bar-front"></div>
		<div class="progress3d-bar-right"></div>
		<div v-if="showtext || text" :class="'progress3d-text ' + (align == 'left' ? align : '')" :style="'color: ' + (color || '#fff')" v-text="text ? text : (value ? (value < 0 ? 0 : (value > 100 ? 100 : value)) : 0) + '%'"></div>
	</div>
</template>

<script>
	module.exports = {
		data: function() {
			return {
				id: 'progress3d_' + Math.random(),
				scaledValue: this.value * (this.valuescale || 1)
			}
		},
		props: [
			'barcolorh',
			'barcolors',
			'height',
			'value',
			'valuescale',
			'text',
			'align',
			'showtext',
			'showzero',
			'color',
			'fontsize',
			'thickrate',
			'flatbottom',
			'during',
		],
		methods: {

		}
	}
</script>
