<style>
	#dv-center-list{
		position: absolute;
		left: 1260px;
		top: 380px;
		width: 203px;
		background: url('../../../public/imgs/index/右侧浮动边框.png') left top no-repeat;
		opacity: 0;
	}
	#dv-center-list .title{
		position: absolute;
		left: 0;
		top: 15px;
		font-size: 18px;
		width: 100%;
		text-align: center;
		font-weight: bold;
	}
	#dv-center-list .title span{
		color: #45edff;
		/* font-family: 'Work Sans', sans-serif; */
		/* font-weight: bold; */
		font-size: 18px;
	}
	#dv-center-list .btns{
		position: absolute;
		left: 0;
		top: 55px;
		width: 300px;
	}
	#dv-center-list .btns img {
		position: absolute;
		left: 8px;
		top: 0;
		width: 90px;
		height: 28px;
		cursor: pointer;
	}
	#dv-center-list .btns img:nth-child(2) { left: 104px }
	#dv-center-list .btns div {
		position: absolute;
		left: 8px;
		top: 0;
		width: 90px;
		color: #87bafd;
		font-size: 14px;
		font-weight: bold;
		line-height: 30px;
		text-align: center;
		pointer-events: none;
	}
	#dv-center-list .btns .btn02 { left: 104px }

	#dv-center-list .list{
		position: absolute;
		left: 0;
		top: 95px;
		font-size: 14px;
		line-height: 22px;
		text-align: left;
		width: 160px;
	}
	#dv-center-list .list-Item { 
		margin-bottom: 5px;
		background: url('../../../public/imgs/icon.png') left 4px no-repeat;
		background-size: 13px;
		padding-left: 20px;
	}
	#dv-center-list i {
		font-style: normal;
		cursor: pointer;
	}
	#dv-center-list i.selected{
		color: #00FFF8;
	}
	#dv-center-list .bottom{
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 5px;
		background: url('../../../public/imgs/index/右侧浮动边框.png') left bottom no-repeat;
	}

</style>

<template>
	<div id="dv-center-list" :style="(currentPageLevel == 3 ? 'display: none' : '') + '; height: ' + (maxHeight * 27 + 105) + 'px'">
		<div class="title" @click="selected(1)"><i v-text="currentArea"></i><span v-if="ProjectList.length>0" v-text="'（' + ProjectList.length + '）'"></span></div>
		<div class="btns">
			<dv-img @click="ComparePrograms" src="imgs/p2/06a794e5-ca98-4ef4-92e5-94c66d96e2d0.png"></dv-img>
			<dv-img @click="CompareTunnels" src="imgs/p2/06a794e5-ca98-4ef4-92e5-94c66d96e2d0.png"></dv-img>
			<div class="btn01">项目对比</div>
			<div class="btn02">隧道对比</div>
		</div>
		<div class="list" :style="'height: ' + (maxHeight * 27) + 'px'">
			<vue-scroll style="padding-left: 20px; wdith: 180px ">
				<div class="list-Item" v-for="(item, index) in ProjectList" :key="index">
					<i @click="selected(2, item.Id)" :class="(currentProgram == item.Id) ? 'selected' : ''" v-text="item.Name"></i>
				</div>
			</vue-scroll>
		</div>
		<div class="bottom"></div>
	</div>
</template>

<script>
	// import * as $ from 'jquery'
	import dataV from '../../bin/dataV'
	import dvImg from './dv-img'

	export default {
		data: function(){
			return {	
				Id: 'CenterListComponent',

				ProjectList: window.ApiData.index.ProjectList,

				currentPageLevel: dataV.Config.initPage.level,		// 大屏当前页面层级，1-全国、2-项目部
				currentArea: dataV.Config.AreaName,

				currentProgram: dataV.Page.CurrentId,

				maxListItems: 10,
			}
		},
		props: ['title'],
		created() {
			window[this.Id] =  this;
		},
		methods: {
			ComparePrograms(all){
				this.$api.request({ Vue: this, apiName: 'programContrast', query: { projectInfoIds: all || 'all' } }).then(response => {
					if (response.state === 'success') {
						const json = JSON.parse(response.data)
						window.CenterWindowsComponent.set('programs', json)
					} else {
						alert(response.message)
					}
				})
			},
			CompareTunnels(all){
				console.log(all)
				this.$api.request({ Vue: this, apiName: 'tunnelContrast', query: { tunnelInfoIds: all || 'all' } }).then(response => {
					if (response.state === 'success') {
						const json = JSON.parse(response.data)
						window.CenterWindowsComponent.set('tunnels', json)
					} else {
						alert(response.message)
					}
				})
			},

			sync(){
				this.currentPageLevel = dataV.Page.CurrentLevel
				this.currentProgram = dataV.Page.CurrentId
			},
			
			selected(level, id){
				dataV.Page.Toggle(this, level, id)
			}
		},
		watch: {  },
		computed: {
			maxHeight () {
				let rowCount = 0, rowIndex = 0
				try {
					this.ProjectList.forEach(item => {
						// item.Name = item.Name + item.Name
						rowCount += item.Name.length > 10 ? (item.Name.length > 20 ? 3 : 2) : 1
						rowIndex++
						if (rowIndex >= this.maxListItems) throw(new Error('exit forEach'));
					})
				} catch(e) { e }
				return rowCount > this.maxListItems ? this.maxListItems : rowCount
			},

		},
		mounted: function(){
			// 加载api数据

			window.CenterListComponent =  this;

		},
		components: {
			'dv-img': dvImg
		}
		
	}
</script>
