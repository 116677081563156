import Config from '../config/Config'
import ModelWindows from './initModelWindows'
import Page from './page'
// import { initSceneViewer } from './initSceneViewer';

const dataV = {
	Config,
	ModelWindows,
	Page,

	Sound: {
		list: {
			'滑入': new Audio('/sounds/10808.wav'),
			'滑出': new Audio('/sounds/696.wav'),
			'打开': new Audio('/sounds/9675.wav'),
			'关闭': new Audio('/sounds/9676.wav'),
			'点击': new Audio('/sounds/02.mp3'),
		},
		play(audioName) {
			// dataV.Sound.stop(audioName)
			dataV.Sound.list[audioName].play()
			// console.log(audioName)
		},
		// stop(audioName){
		// 	try{
		// 		dataV.Sound.list[audioName].pause();
		// 	}catch(e){ console.log(e) }
		// }

		// play(audioName, fun) {
		// 	dataV.Sound.stop()
		// 	if(!dataV.audio) dataV.audio = new Audio(dataV.Sound.list[audioName])
		// 	dataV.audio.play()
		// 	dataV.audio.addEventListener('ended', function() {
		// 		dataV.Sound.stop()
		// 		if(fun) {
		// 			fun();
		// 			fun = null;
		// 		}
		// 	}, false);
		// },
		// stop(){
		// 	if(dataV.audio){
		// 		try{
		// 			dataV.audio.pause();
		// 		}catch(e){ console.log(e) }
		// 	}
		// }
	},
	
	DateTime: {
		format(fmt, date) {
			let ret;
			if(!date) date = new Date();
			const opt = {
				"Y+": date.getFullYear().toString(),        // 年
				"m+": (date.getMonth() + 1).toString(),     // 月
				"d+": date.getDate().toString(),            // 日
				"H+": date.getHours().toString(),           // 时
				"M+": date.getMinutes().toString(),         // 分
				"S+": date.getSeconds().toString()          // 秒
				// 有其他格式化字符需求可以继续添加，必须转化成字符串
			};
			for (let k in opt) {
				ret = new RegExp("(" + k + ")").exec(fmt);
				if (ret) {
					fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
				}
			}
			return fmt;
		},
		getWeekCH(date) {
			if(!date) date = new Date();
			var day = date.getDay();
			var weeks = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
			var week = weeks[day];
			return week;
		}
	}
}

window.dataV = dataV;

// new initSceneViewer(dataV);

export default dataV