<style>
.img-rotator-box {
  position: absolute;
  left: 0;
  top: 0;
}
.img-rotator-box div.imgs {
  position: absolute;
  width: 100%;
  height: 32%;
  top: 55px;
  background: url(/imgs/p1/圆圈.png) no-repeat center 40%;
  background-size: 70% 70%;
}
.img-rotator-box div.title {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 40px;
  font-size: 13px;
}
.img-rotator-box div.explains {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 20px;
  font-size: 13px;
  color: #00ffff;
}
</style>

<template>
  <div class="img-rotator-box" :style="'width: ' + (width ? width + 'px' : '100%') + '; height: ' + (height ? height + 'px' : '100%')" v-on:click="$emit('click')">
    <div class="imgs">
      <img v-for="(item, index) in trueData" :key="index" :src="item.img" :width="img.width" :height="img.height" />
    </div>
    <div class="title"></div>
    <div class="explains"></div>
  </div>
</template>

<script>
import * as $ from 'jquery';

export default {
  data() {
    return {
      id: "img_" + Math.random(),

      timer: null,

      trueData: this.data,
      imgs: null,

      img: {
        width: 100,
        height: 150,
        src: "",
        title: "",
      },
    };
  },
  props: ["data", "delay", "during", "width", "height"],
  methods: {
    init() {
      var that = this;
      //
      this.trueData = this.data.slice(0);
      this.trueData.reverse();

      this.imgs = $(".imgs img");
      if (this.imgs.length == 0) return;

      // 初始化
      this.imgs.index = 0;
      $.each(this.imgs, function (index, img) {
        var imginfo = that.imgXY(index);
        $(img).css({
          position: "absolute",
          width: imginfo.width,
          height: imginfo.height,
          left: imginfo.left,
          top: imginfo.top,
          opacity: imginfo.opacity,
          "z-index": imginfo.z,
        });
      });
      $(".img-rotator-box .title").text(this.trueData[0].name);
      $(".img-rotator-box .explains").text(this.trueData[0].content);
      this.img.src = this.trueData[0].img;
      this.img.title = this.trueData[0].name;

      // 加载动画
      if (this.timer) clearInterval(this.timer);
      this.timer = setInterval(this.animate, this.delay || 4000);
    },

    animate() {
      var that = this;
      //
      var count = this.imgs.length;
      this.imgs.index++;
      this.imgs.current = this.imgs.index % count;
      if (this.imgs.current == 0) this.imgs.current = count;

      $.each(this.imgs, function (index, img) {
        var imginfo = that.imgXY((index + that.imgs.index) % count);
        $(img).animate(
          {
            position: "absolute",
            width: imginfo.width,
            height: imginfo.height,
            left: imginfo.left,
            top: imginfo.top,
            opacity: imginfo.opacity,
            "z-index": imginfo.z,
          },
          this.during || 1500,
          function () {
            if (index == that.imgs.index % count) {
              $(".img-rotator-box .title").text(
                that.trueData[count - (index == 0 ? count : index)].name
              );
              $(".img-rotator-box .explains").text(
                that.trueData[count - (index == 0 ? count : index)].content
              );
              that.img.src =
                that.trueData[count - (index == 0 ? count : index)].img;
              that.img.title =
                that.trueData[count - (index == 0 ? count : index)].name;
            }
          }
        );
      });
    },

    imgXY(index) {
      var count = this.imgs.length;
      // Y方向，位置系数
      var img_y = index > count / 2 ? count - index : index;
      // X方向，位置系数
      var img_x =
        (Math.floor(Math.sin(Math.PI * 2 * (index / count)) * 100) / 100) *
        (img_y > count / 4 ? 0.7 : 1);
      // Left位置, 补偿系数
      var img_left_rate = 1 + img_x * 0.75;
      // Top位置, 补偿系数，同时用于透明度参数
      var img_top_rate = 1 - (img_y * (img_y > count / 4 ? 1.5 : 0.8)) / count;
      var img_width = this.img.width * Math.pow(2 / 3, img_y);
      var img_height = this.img.height * Math.pow(2 / 3, img_y);
      var zIndex = count - index;
      return {
        x: img_x,
        y: img_y,
        z: zIndex,
        width: img_width + "px",
        height: img_height + "px",
        left:
          ($(".img-rotator-box .imgs").width() / 2) * img_left_rate -
          img_width / 2 +
          "px",
        top:
          ($(".img-rotator-box .imgs").height() / 2) * img_top_rate -
          img_height / 2 +
          "px",
        opacity: img_top_rate,
      };
    },

    // getFileUrl(url) {
    //   return dataV.Config.originalSystem.file_root + url;
    // },
  },
  mounted() {
    this.init();

    window.HonourViewer = this;
  },
};
</script>

