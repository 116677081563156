<style>
#dv-left-index { position: absolute; left: -1100px; top: 20px; width: 1000px; }

#dv-left-index #section_caption { position: absolute; left: 50px; top: 35px; font-size: 26px; font-weight: 550; }
#dv-left-index #logo { position: absolute; left: 45px; top: 100px; width: 122px; }
#dv-left-index #info-back { position: absolute; left: 170px; top: 100px; }
#dv-left-index #section_title { position: absolute; left: 190px; top: 111px; font-size: 14px; font-weight: bold; }
#dv-left-index #section_description { position: absolute; left: 185px; top: 137px; text-align: justify; width: 265px; line-height: 160%; height: 75px; }
#dv-left-index #targets { position: absolute; left: 45px; top: 230px; }
#dv-left-index .tabs { position: absolute; left: 45px; top: 248px; font-size: 12px; font-weight: bold; }
#dv-left-index .tabs .tabs-titles span { display: inline-block; text-align: center; line-height: 12px; width: 104px; border-right: 1px solid #0bffff52; color: #0BFDFF; cursor: pointer; }
#dv-left-index .tabs .tabs-titles span.selected { color: white;}
#dv-left-index .tabs .tabs-titles span:last-child { border-right: 0 }
#dv-left-index .tabs .tabs-panels { position: absolute; left: 10px; top: 30px; font-size: 12px; font-weight: normal; line-height: 20px; }
#dv-left-index .tabs .tabs-panels span { text-align: left; display: none; width: 400px; padding: 0 3px }
#dv-left-index .tabs .tabs-panels span.selected { display: inline-block; }


#dv-left-index #videos_back { position: absolute; left: 70px; top: 355px; }
#dv-left-index #videos_title { position: absolute; left: 88px; top: 362px; font-size: 16px; font-weight: bold; }

#dv-left-index .videos{ position: absolute; left: 45px; top: 335px; }
#dv-left-index .videos .videoBox{ position: absolute; left: 9px; top: 8px; width: 400px; height: 225px; text-align: center; line-height: 176px; color: #0055AA; }
#dv-left-index .videos .video-0-bg{ position: absolute; left: 156px; top: 88px; width:     0; height: 0; }
#dv-left-index .videos .video-0   { position: absolute; left: 0;     top: 0;    width: 400px; height: 225px; }

#dv-left-index .videos .video-1{ position: absolute; left: 11px;  top: 250px; width: 90px; height: 62px; border: 1px solid #0055AA; }
#dv-left-index .videos .video-2{ position: absolute; left: 113px; top: 250px; width: 90px; height: 62px; border: 1px solid #0055AA; }
#dv-left-index .videos .video-3{ position: absolute; left: 215px; top: 250px; width: 90px; height: 62px; border: 1px solid #0055AA; }
#dv-left-index .videos .video-4{ position: absolute; left: 317px; top: 250px; width: 90px; height: 62px; border: 1px solid #0055AA; }

#dv-left-index .bottomTabs { position: absolute; left: 45px; top: 682px; font-size: 16px; font-weight: bold; }
#dv-left-index .bottomTabs .tabs-titles span { display: inline-block; text-align: center; width: 137px; height: 47px; cursor: pointer; background: url('../../../public/imgs/index/bt.png') left top no-repeat; line-height: 47px; margin-right: 5px }
#dv-left-index .bottomTabs .tabs-titles span.selected { background: url('../../../public/imgs/index/bt-hover.png') left top no-repeat; }
#dv-left-index .bottomTabs .tabs-titles span:last-child { border-right: 0 }
#dv-left-index .bottomTabs .tabs-panels { position: absolute; left: 10px; top: 30px; font-size: 12px; font-weight: normal; line-height: 20px; }
#dv-left-index .bottomTabs .tabs-panels div { display: none; width: 420px; }
#dv-left-index .bottomTabs .tabs-panels div.selected { display: inline-block; }

#dv-left-index .honours{ position: absolute; left: 45px; top: 740px; width: 420px; }
#dv-left-index .honours .text{ position: absolute; left: 35px; top: 0;}
#dv-left-index .honours .text div{ position: absolute; left: 0; width: 334px; text-align: center; }
#dv-left-index .honours .text div.topic{ top: 22px; font-size: 15px; font-weight: bold; }
#dv-left-index .honours .bg-view-honours{ position: absolute; left: 160px; top: 130px; width: 10px; height: 10px; z-index: 100; overflow: hidden; display: none; }
#dv-left-index .honours .bg-view-honours .honour-view-bg{ position: absolute; left: 0; top: 0; }
#dv-left-index .honours .bg-view-honours .honour-view-img{ position: absolute; left: 5px; top: 63px; width: 626px; height: 862px }
#dv-left-index .honours .bg-view-honours .honour-view-title{ position: absolute; left: 14px; top: 32px; width: 635px; font-size: 16px; font-weight: bold; }
#dv-left-index .honours .bg-view-honours .small-list{ position: absolute; left: 638px; top: 0; width: 150px; text-align: center; }
#dv-left-index .honours .bg-view-honours .small-list .small-list-pages span{ display: inline-block; border: 1px solid white; height: 20px; line-height: 20px; overflow: hidden; border-radius: 0; }
#dv-left-index .honours .bg-view-honours .small-list .small-list-pages span:nth-child(1),
#dv-left-index .honours .bg-view-honours .small-list .small-list-pages span:nth-child(3){ width: 20px; cursor: pointer; }
#dv-left-index .honours .bg-view-honours .small-list .small-list-pages span:nth-child(2){ width: 90px }
#dv-left-index .honours .bg-view-honours .small-list .small-list-imgs{ width: 100%; height: 908px; overflow: hidden; background-color: #0546E9; }
#dv-left-index .honours .bg-view-honours .small-list .small-list-imgs img{ width: 60px; border-width: 0; }
#dv-left-index .honours .bg-view-honours .small-list .small-list-imgs img.current{ width: 80px; border: 1px solid #01F2F5; }

#dv-left-index .resources{ position: absolute; left: 45px; top: 740px; width: 420px; }
#dv-left-index .resources .bg{ position: absolute; left: 0; top: 0; }
#dv-left-index .resources .bgs{ position: absolute; left: 0; top: 10px; width: 100%; height: 250px; }
#dv-left-index .resources .item-54{ position: absolute; left: 30px; top: 15px; width: 160px; height: 30px; font-size: 14px; font-weight: normal; text-align: center; }
#dv-left-index .resources .preset01{ position: absolute; right: 10px; top: 10px; width: 220px; }
#dv-left-index .resources .preset01-qrcode{ position: absolute; left: 30px; top: 45px; width: 160px; z-index: 100; }
#dv-left-index .resources .preset_pages{ position: absolute; left: 30px; top: 215px; width: 160px; text-align: center; }
#dv-left-index .resources .preset_pages .pageIcon{ display: inline-block; margin: 0 2px; width: 8px; cursor: pointer; }

#dv-left-index .defectReasons{ position: absolute; left: 45px; top: 740px; width: 420px; }
#dv-left-index .defectReasons .bg{ position: absolute; left: 0; top: 0; }
#dv-left-index .defectReasons .bgs{ position: absolute; left: 0; top: 10px; width: 100%; height: 260px; }
#dv-left-index .defectReasons .explainbox01{ position: absolute; left: 15px; top: 0; font-size: 14px; font-weight: normal; width: 385px; height: 240px; line-height: 150%; }
#dv-left-index .defectReasons .explainbox01 .item{ float: left; width: 100%; margin-bottom: 7px; }
#dv-left-index .defectReasons .explainbox01 .item .name{ float: left; width: 200px; font-size: 12px; color: #47F9FF; margin-top: 5px; }
#dv-left-index .defectReasons .explainbox01 .item .explain{ float: left; width: 100%; margin-top: 0; font-size: 12px; line-height: 120%; text-align: justify; }

#dv-left-index div.opacity-0 { opacity: 0; }

</style>

<template>
  <div id="dv-left-index">
    <div>
      <div id="section_caption" v-text="AreaName + '介绍'"></div>
      <dv-img id="logo" src="./imgs/index/铁建LOGO动/logo-D.png"></dv-img>
      <dv-img id="info-back" src="./imgs/index/边框-1.png"></dv-img>
      <div id="section_title">项目介绍</div>
      <div id="section_description"><vue-scroll><div v-text="AreaDescription"></div></vue-scroll></div>
      <dv-img id="targets" src="./imgs/index/边框-2.png"></dv-img>
      <div class="tabs">
        <div class="tabs-titles">
          <span :class="targetIndex == 1 ? 'selected' : ''" @click="toggleTarget(1)">终极检测目标</span>
          <span :class="targetIndex == 2 ? 'selected' : ''" @click="toggleTarget(2)">一个短期目标</span>
          <span :class="targetIndex == 3 ? 'selected' : ''" @click="toggleTarget(3)">两个实际目标</span>
          <span :class="targetIndex == 4 ? 'selected' : ''" @click="toggleTarget(4)">三级管理制度</span>
        </div>
        <div class="tabs-panels">
          <span :class="targetIndex == 1 ? 'selected' : ''">工程检测中心检测工程实体质量结果中质量问题越来越少，直至为0</span>
          <span :class="targetIndex == 2 ? 'selected' : ''">二公司项目部隧道新浇筑混凝 土衬砌雷达检测全覆盖</span>
          <span :class="targetIndex == 3 ? 'selected' : ''">监督、控制工程在建实体工程质量；指导工程施工工艺提高施工队伍的工作能力</span>
          <span :class="targetIndex == 4 ? 'selected' : ''">第一级工程检测中心职能部门统一标准，统一尺度，统一格式，第二级区域工程检测部、第三级项目工程检测部严格执行</span>
        </div>
      </div>
    </div>
    <div class="videos">
      <dv-img class="bg-videos" src="imgs/index/视频边框.png"></dv-img>
      <div class="videoBox">
        <dv-img id="video-0-bg" class="video-0-bg" src="imgs/p1/76126803-86ed-4e94-9f3a-589f5cb608e8.png"></dv-img>
        <video id="video-0" class="video-0" autoplay="autoplay" loop="loop" muted="muted" :src="currentVideo" @click="scaleVideo"></video>
        视频已放大，点击视频恢复
      </div>
      <dv-img v-for="(video, index) in VideoList.slice(0, 4)" :key="index" :class="'video-' + (index + 1) + ' videoImg'" :src="ImgBaseUrl + video.CoverLink" :data-src="video.OssUrl" @click="toggleVideo"></dv-img>
    </div>
    <div class="bottomTabs">
      <div class="tabs-titles">
        <span :class="bottomTabIndex == 1 ? 'selected' : ''" @click="toggleBottomTab(1)">荣誉证书</span>
        <span :class="bottomTabIndex == 2 ? 'selected' : ''" @click="toggleBottomTab(2)">预制件二维码</span>
        <span :class="bottomTabIndex == 3 ? 'selected' : ''" @click="toggleBottomTab(3)">缺陷类型介绍</span>
      </div>
    </div>
    <div :class="'honours' + (bottomTabIndex != 1 ? ' opacity-0' : '')">
      <dv-img class="bg-honours" src="imgs/index/证书轮换边框.png"></dv-img>
      <div class="text">
        <div class="topic">获奖证书</div>
      </div>
			<dv-img-rotator id="centerHonours" width="420" height="240" delay="3000" :data="centerHonours" @click="enlargeHonour" style="margin-top: 40px"></dv-img-rotator>
      <div class="bg-view-honours">
        <dv-img class="honour-view-bg" src="imgs/honours/证书背景.png"></dv-img>
        <dv-img class="honour-view-img" :src="honourImg.src" @click="narrowHonour"></dv-img>
        <div class="honour-view-title" v-text="honourImg.title"></div>
        <div class="small-list">
          <div class="small-list-pages">
            <span @click="toggleHonourInfo(0, 'prev')">&lt;</span>
            <span v-text="honourImg.index + ' / ' + honourImg.count"></span>
            <span @click="toggleHonourInfo(0, 'next')">&gt;</span>
          </div>
          <div class="small-list-imgs">
            <div v-for="(item, index) in centerHonours" :key="'honour_small_img_' + index"><img :class="index == honourImg.index - 1 ? 'current' : ''" :src="item.img" @click="toggleHonourInfo(index)"></div>
          </div>
        </div>
      </div>
    </div>
    <div :class="'resources' + (bottomTabIndex != 2 ? ' opacity-0' : '')">
      <dv-img class="bg" src="imgs/index/证书轮换边框.png"></dv-img>
      <div class="bgs">        
        <div class="item-54" v-text="PresetParts[currentPreset].title"></div>
        <!-- 预制件 -->
        <dv-img class="preset01" src="imgs/p11/4cae0ce4-e089-4876-bdac-f3253c0cd87e.png"></dv-img>
        <dv-img class="preset01-qrcode" :src="'imgs/检测基地 2/预制件二维码/' + PresetParts[currentPreset].title + '.png'"></dv-img>
        <div class="preset_pages">
          <dv-img class="pageIcon" v-for="(item, index) in PresetParts" :key="'pageIcon_' + index" :title="index" @mouseover="togglePreset" :src="'imgs/p2/' + (currentPreset == index ? '06f15087-c767-45be-977e-2e00800cfac6' : '2dc0cea5-876d-4e53-9c26-10debca2d21b') + '.png'" :data-index="index"></dv-img>
        </div>
      </div>
    </div>
    <div :class="'defectReasons' + (bottomTabIndex != 3 ? ' opacity-0' : '')">
      <dv-img class="bg" src="imgs/index/证书轮换边框.png"></dv-img>
      <div class="bgs">        
        <div class="explainbox01">
          <vue-scroll>
            <div class="item" v-for="(item, index) in PresetParts[currentPreset].defects" :key="'explainitem_' + index">
              <div class="name" v-text="item.name"></div>
              <div class="explain" v-text="item.explain"></div>
            </div>
          </vue-scroll>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as $ from 'jquery';
import dataV from '../../bin/dataV';
import dvImg from "./dv-img.vue";
import dvImgRotator from "./dv-img-rotator.vue";

export default {
  data() {
    return {
      Id: 'LeftIndexComponent',

      AreaName: dataV.Config.AreaName,
      AreaDescription: dataV.Config.AreaDescription,
      ImgBaseUrl: dataV.Config.baseUrl.img,
      VideoList: window.ApiData.index.VideoList,
      currentVideo: window.ApiData.index.VideoList.length > 0 ? window.ApiData.index.VideoList[0].OssUrl : '',
      centerHonours: window.ApiData.index.HonorBookList.map(item => {return { name: item.Name, content: item.BelongCategory, img: dataV.Config.baseUrl.img + item.PicUploadUrl }}),

      currentPreset: 0,
      PresetParts: dataV.Config.PresetParts,

      currentPageLevel: dataV.Config.initPage.level,
      currentArea: dataV.Config.AreaName,
      currentProgram: dataV.Config.initPage.program,
      currentTunnel: dataV.Config.initPage.tunnel,
      currentTunnelID: dataV.Config.initPage.tunnelId,
      currentDefect: dataV.Config.initPage.defect,

      honourImg: {      // 荣誉证书放大后的信息
        src: '',
        title: '',
        index: 0,
        count: 0,
      },

      WindowStatus: {
        isCompanyVideoOpen: false,
        isHonourInfoOpen: false,
      },

      targetIndex: 1,
      bottomTabIndex: 1,
            
    };
  },
  props: [],
  methods: {
    
    // 切换重点工作
    toggleBottomTab(index){
      this.bottomTabIndex = index
    },

    // 切换重点工作
    toggleTarget(index){
      this.targetIndex = index
    },

    // 手动方式，设置当前模块的语音声控指令，已达到手动控制的效果
    scaleVideo(){
      if($(event.target).width() < 500){
        this.enlargeVideo();
      }else{
        this.narrowVideo();
      }
    },

    // 放大视频
    enlargeVideo(){
      dataV.ModelWindows.close('全国视频');
      this.WindowStatus.isCompanyVideoOpen = true;

      dataV.Sound.play('打开')
      var el = document.getElementById('video-0');
      $('#video-0-bg').css({'z-index': 1}).animate({
        left: '346px',
        top: '-237px',
        width: '1148px',
        height: '774px',
      }, 500);
      $(el).css({'z-index': 1}).animate({
        left: '352px',
        top: '-171px',
        width: '1136px',
        height: '639px',
      }, 500, function(){
        el.muted = false;
        el.loop = false;
        el.currentTime = 0;
      });
    },

    // 缩小视频
    narrowVideo(){
      // 缩小全国区域视频
      if(this.WindowStatus.isCompanyVideoOpen){
        dataV.Sound.play('关闭')
        this.WindowStatus.isCompanyVideoOpen = false;
        var el = document.getElementById('video-0');
        el.muted = true;
        el.loop = true;
        $('#video-0-bg').animate({
          left: '156px',
          top: '88px',
          width: 0,
          height: 0,
        }, 500);
        $(el).animate({
          left: 0,
          top: 0,
          width: '400px',
          height: '225px'
        }, 500);
      }
    },
    
    // 切换视频
    toggleVideo () {
      var $v = $(event.target);
      this.currentVideo = $v.data('src');
    },

    // 放大证书
    enlargeHonour(){
      var that = this;
      //
      if(that.currentPageLevel == 1){
        dataV.ModelWindows.close('荣誉证书');
        that.WindowStatus.isHonourInfoOpen = true;

        // 获取轮盘动画中的证书信息
        console.log(window.HonourViewer)
        this.honourImg.src = window.HonourViewer.img.src;
        this.honourImg.title = window.HonourViewer.img.title;
        this.honourImg.index = window.HonourViewer.imgs.current;
        this.honourImg.count = window.HonourViewer.imgs.length;

        dataV.Sound.play('打开')
        var $el = $('.honours .bg-view-honours');
        $el.css({
          left: '160px',
          top: '100px',
          width: '10px',
          height: '10px',
        }).show().animate({
          left: '530px',
          top: '-680px',
          width: (645 + 152) + 'px',
          height: '932px',
        }, 500, function(){
          that.honourViewListAction();
        });
      }				
    },
    
    // 关闭证书
    narrowHonour(){
      this.WindowStatus.isHonourInfoOpen = false;
      //
      dataV.Sound.play('关闭')
      var $el = $('.honours .bg-view-honours');
      $el.animate({
        left: '930px',
        top: '-165px',
        width: 0,
        height: 0,
      }, 500, function(){
        $el.hide()
      });
    },
    
    // 切换证书浏览器中的证书信息
    toggleHonourInfo(index, toggle){
      if(toggle == 'prev'){
        index = this.honourImg.index - 2;
        if(index < 0) index = this.centerHonours.length - 1;
      }else if(toggle == 'next'){
        index = this.honourImg.index;
        if(index >= this.centerHonours.length) index = 0;
      }

      this.honourImg.src = this.centerHonours[index].img;
      this.honourImg.title = this.centerHonours[index].name;
      this.honourImg.index = index + 1;

      this.honourViewListAction();
    },

    // 证书浏览器右侧图片列表响应
    honourViewListAction(){
      var that = this;
      //
      var imgHeight = 90;	
      var $imgFrame = $('#dv-left #p_Left_1 .honours .bg-view-honours .small-list .small-list-imgs');
      var frameHeight = $imgFrame.height();
      var max = Math.floor(frameHeight / imgHeight);
      if(that.honourImg.index > (max - 1)){
        $imgFrame.scrollTop((that.honourImg.index - (max - 1)) * imgHeight);
      }else{
        $imgFrame.scrollTop(0);
      }
    },

    // 切换预置件
    togglePreset(type){
      if(type == 'prev'){
        var _page = this.currentPreset - 1;
        if(_page < 0) _page = this.baseinfo.presetparts.length - 1;
        this.currentPreset = _page;
      }else if(type == 'next'){
        _page = this.currentPreset + 1;
        if(_page >= this.baseinfo.presetparts.length) _page = 0;
        this.currentPreset = _page;
      }else{
        var $this = $(event.target);
        this.currentPreset = $this.data('index');
      }
    },
      
  },
  created() {
			window[this.Id] =  this;
  },
  mounted() {
    // 注册弹出窗口
    dataV.ModelWindows.regist('全国视频', this.WindowStatus, 'isCompanyVideoOpen', true, this.narrowVideo);
    dataV.ModelWindows.regist('荣誉证书', this.WindowStatus, 'isHonourInfoOpen', true, this.narrowHonour);
  },
  components: {
    "dv-img": dvImg,
    "dv-img-rotator": dvImgRotator,
  },
};
</script>
